<!--
  功能：预览pdf
  作者：Yu Duan
  邮箱：yu.duan@edoovo.com
  时间：2020年08月28日 20:00:37
-->
<template>
  <div class="gg_view">
    <div class="gg_view_head">
      <el-tooltip popper-class="file-tooltip-bottom" :visible-arrow="false" content="close" placement="bottom">
        <i v-if="needBack" @click="back" class="back-btn iconfont iconhoutui"></i>
      </el-tooltip>
      <img class="pdfIcon" src="./pdf_icon.svg" />{{ pdfName }}
    </div>
    <el-tooltip popper-class="file-tooltip-bottom" :visible-arrow="false" content="Print" placement="bottom">
      <div class="print-btn" v-if="needPrint" @click="newPageViewPdf">
        <i class="iconfont iconprint-24px"></i>
      </div>
    </el-tooltip>
    <div class="gg_view_pdf" ref="view">
      <canvas
        v-if="getPdfUrl"
        :style="{
          width: scaleWidth,
          transform: `translate(-50%, -50%)`,
          marginLeft: pdfEle.left + 'px',
          marginTop: pdfEle.top + 'px'
        }"
        ref="pdf"
        id="pdf"
        :class="{ pdf: true, can_move: moveStatus }"
      >
      </canvas>
      <el-tooltip
        popper-class="file-tooltip-top"
        :visible-arrow="false"
        content="Previous"
        placement="top"
      >
        <i
          @click="changePdfPage('pre')"
          class="iconfont iconnavigate_before-px"
          v-show="currentPage > 1"
        />
      </el-tooltip>
      <el-tooltip popper-class="file-tooltip-top" :visible-arrow="false" content="Next" placement="top">
        <i
          @click="changePdfPage('next')"
          class="iconfont iconnavigate_next-px"
          v-show="currentPage < pageCount"
        />
      </el-tooltip>
    </div>
    <ul class="gg_view_actions">
      <li v-if="getPdfUrl" class="page_number">Page {{ currentPage }} / {{ pageCount }}</li>
      <el-tooltip
        popper-class="file-tooltip-top"
        :visible-arrow="false"
        content="Rotate clockwise"
        placement="top"
      >
        <li class="iconfont iconrotate_right-24px rotate-btn" v-if="imgUrl" @click="rotateImg" />
      </el-tooltip>
      <el-tooltip
        popper-class="file-tooltip-top"
        :visible-arrow="false"
        content="Zoom out"
        placement="top"
      >
        <li
          class="zoom-out-btn"
          :class="{ iconfont: true, 'iconzoom-out': true, disabled: outDisabled }"
          @click="zoom('out')"
        />
      </el-tooltip>
      <el-tooltip
        popper-class="file-tooltip-top"
        :visible-arrow="false"
        content="Reset zoom"
        placement="top"
      >
        <li
          :class="{
            iconfont: true,
            'iconzoom-reset': true,
            disabled: resetDisabled
          }"
          @click="zoom()"
        />
      </el-tooltip>
      <el-tooltip
        popper-class="file-tooltip-top"
        :visible-arrow="false"
        content="Zoom in"
        placement="top"
      >
        <li
          :class="{ iconfont: true, 'iconadd-24px5': true, disabled: inDisabled }"
          @click="zoom('in')"
        />
      </el-tooltip>
    </ul>
    <viewer
      v-model="isPreviewImg"
      :src="imgUrl"
      :has-mask="false"
      :context-menu="false"
      ref="imgViewer"
    />
  </div>
</template>

<script>
import pdf from 'vue-pdf';
import viewer from './viewer';

export default {
  data() {
    return {
      pdfWidth: '80%',
      currentPage: 1, // pdf文件页码
      pageCount: 0,
      pageTimer: null,
      pdfEle: {
        width: 0,
        height: 0,
        scale: 1,
        left: 0,
        top: 0
      },
      mousePosition: {
        x: 0,
        y: 0
      },
      newPdfUrl: '',
      pdfData: '',
      moveStatus: false,
      canChange: false,
      isPreviewImg: false,
      imgStyle: {
        width: '',
        height: ''
      },
      rotate: 0,
      imgScale: 1
    };
  },
  computed: {
    getPdfUrl() {
      if (location.href.includes('edoovo.com')) {
        return this.$route.query.url;
      }
      const url = this.$route.query.url || this.$route.query.imgUrl;
      return url.replace(
        'https://edoovo-hk.oss-accelerate.aliyuncs.com',
        'http://localhost:8080/oss'
      );
    },
    imgUrl() {
      return this.$route.query.imgUrl;
    },
    pdfName() {
      return this.$route.query.name || this.$route.query.url.match(/\/([^/]*)$/)[1];
    },
    scaleWidth() {
      if (this.pdfEle.scale === 1) {
        return this.pdfWidth;
      }
      return this.pdfEle.scale * parseInt(this.pdfWidth) + 'px';
    },
    needPrint() {
      const needPrint = this.$route.query.needPrint || 0;
      return +needPrint === 1;
    },
    needBack() {
      const needBack = this.$route.query.needBack || 0;
      return +needBack === 1;
    },
    outDisabled() {
      if (this.imgUrl) {
        return this.imgScale === 0;
      }
      return this.pdfEle.scale === 1;
    },
    inDisabled() {
      if (this.imgUrl) {
        return this.imgScale === 2;
      }
      return this.pdfEle.scale === 2;
    },
    resetDisabled() {
      if (this.imgUrl) {
        return this.imgScale === 1;
      }
      return this.pdfEle.scale === 1 && !this.mousePosition.x && !this.mousePosition.y;
    }
  },
  mounted() {
    this.$nextTick(() => {
      if (this.imgUrl) {
        this.initImg();
      } else {
        let self = this,
          loadingTask = pdf.createLoadingTask(this.getPdfUrl);
        self.newPdfUrl = loadingTask;
        self.newPdfUrl.promise
          .then((pdf) => {
            self.pageCount = pdf.numPages;
            self.pdfData = pdf;
            self.setCurrentCanvas();
          })
          .catch((err) => {});
        document.addEventListener('keypress', this.spaceDown, false);
        document.addEventListener('keyup', this.spaceUp, false);
        document.addEventListener('mouseleave', this.outOfWindow, false);
      }
    });
  },
  /**
   * @description 组件销毁之前移除代理在document上的esc事件
   * @returns { void }
   */
  beforeDestroy() {
    document.removeEventListener('keypress', this.spaceDown);
    document.removeEventListener('keyup', this.spaceUp);
    document.removeEventListener('mouseleave', this.outOfWindow);
  },
  methods: {
    back() {
      this.$router.go(-1);
    },
    initImg() {
      if (!this.imgUrl) {
        return;
      }
      this.isPreviewImg = true;
    },
    /**
     * @description 空格键按下触发拖动功能
     * @param { object } e 按下的按键事件
     * @returns { void }
     */
    spaceDown(e) {
      if (e.which === 32 || e.keyCode === 32) {
        if (!this.moveStatus && this.$refs.pdf.$el) {
          this.moveStatus = true;
          this.$refs.pdf.$el.addEventListener('mousedown', this.mouseDown);
          this.$refs.pdf.$el.addEventListener('mouseup', this.mouseUp);
        }
      }
    },
    /**
     * @description 空格键弹起触发注销功能
     * @param { object } e 按下的按键事件
     * @returns { void }
     */
    spaceUp(e) {
      if ((e.which === 32 || e.keyCode === 32) && this.$refs.pdf.$el) {
        this.moveStatus = false;
        this.$refs.pdf.$el.removeEventListener('mousedown', this.mouseDown);
        this.$refs.pdf.$el.removeEventListener('mouseup', this.mouseUp);
      }
    },
    /**
     * @description 鼠标左键谈按下触发移动功能
     * @param { object } e 鼠标事件
     * @returns { void }
     */
    mouseDown(e) {
      this.mousePosition = { x: e.x, y: e.y };
      this.$refs.pdf.$el && this.$refs.pdf.$el.addEventListener('mousemove', this.mouseMove);
    },
    /**
     * @description 鼠标左键弹起触发移动功能
     * @param { object } e 鼠标事件
     * @returns { void }
     */
    mouseUp(e) {
      this.mousePosition = { x: e.x, y: e.y };
      this.$refs.pdf.$el && this.$refs.pdf.$el.removeEventListener('mousemove', this.mouseMove);
    },
    /**
     * @description 鼠标左键移动事件
     * @param { object } e 鼠标事件
     * @returns { void }
     */
    mouseMove(e) {
      this.pdfEle.left += e.x - this.mousePosition.x;
      this.pdfEle.top += e.y - this.mousePosition.y;
      this.mousePosition = { x: e.x, y: e.y };
    },
    /**
     * @description 不小心移出浏览器范围，中断鼠标事件
     * @returns { void }
     */
    outOfWindow() {
      this.$refs.pdf.$el &&
        this.moveStatus &&
        this.$refs.pdf.$el.removeEventListener('mousemove', this.mouseMove);
    },
    /**
     * @description 缩放pdf
     * @param { out | in } type 缩放方式
     * @returns { void }
     */
    zoom(type) {
      if (this.imgUrl) {
        if (type === 'out' && !this.outDisabled) {
          this.imgScale--;
          this.$refs.imgViewer.zoomOut();
        } else if (type === 'in' && !this.inDisabled) {
          this.imgScale++;
          this.$refs.imgViewer.zoomIn();
        } else if (!type) {
          this.imgScale = 1;
          this.$refs.imgViewer.resetImage();
        }
        return;
      }
      if (type === 'out' && this.pdfEle.scale > 1) {
        this.pdfEle.scale = +(this.pdfEle.scale - 0.2).toFixed(1);
      } else if (type === 'in' && this.pdfEle.scale < 2) {
        this.pdfEle.scale = +(this.pdfEle.scale + 0.2).toFixed(1);
      } else if (!type && (this.pdfEle.scale > 1 || this.mousePosition.x || this.mousePosition)) {
        this.pdfEle.scale = 1;
        this.pdfEle.left = 0;
        this.pdfEle.top = 0;
        this.mousePosition = { x: 0, y: 0 };
      }
    },
    /**
     * @description pdf加载完成之后动态计算pdf宽高
     * @returns { void }
     */
    computedSize(width, height) {
      // const _pdf = this.$refs.pdf;
      // if (!_pdf.$el) {
      //   return;
      // }
      const _canvas = this.$refs.pdf;
      const pdf_w = _canvas.offsetWidth;
      const pdf_h = _canvas.offsetHeight;
      if (pdf_h > pdf_w || pdf_h / pdf_w > 0.57 || pdf_h > this.$refs.view.offsetHeight) {
        let point = this.$refs.view.offsetHeight / pdf_h;
        this.pdfWidth = `${pdf_w * point}px`;
      } else {
        this.pdfWidth = `calc(100% - 252px)`;
      }
      this.$nextTick(() => {
        // if (pdf_h > this.$refs.view.offsetHeight) {
        //   this.pdfWidth = (this.$refs.view.offsetHeight / pdf_h) * _canvas.offsetWidth + 'px';
        // } else {
        this.pdfWidth = _canvas.offsetWidth + 'px';
        // }
      });
    },
    loadPdfHandler() {
      this.currentPage = 1; // 加载的时候先加载第一页
    },
    setCurrentCanvas(type) {
      let that = this;
      console.log(this.getPdfUrl);
      that.pdfData.getPage(that.currentPage).then(function (page) {
        let viewport = page.getViewport({ scale: 2 });
        // ipad使用低分辨率
        let canvas = document.getElementById('pdf');
        let context = canvas.getContext('2d');
        canvas.height = viewport.height;
        canvas.width = viewport.width;
        let renderContext = {
          canvasContext: context,
          viewport: viewport
        };
        page
          .render(renderContext)
          .promise.then(function () {
            that.canChange = true;
            page.cleanup();
            if (that.pdfWidth == '80%') {
              that.computedSize();
            }
          })
          .catch((err) => {
            setTimeout(() => {
              that.canChange = true;
            }, 2000);
            page.cleanup();
            if (that.pdfWidth == '80%') {
              that.computedSize();
            }
          });
      });
    },
    changePdfPage(type) {
      if (this.pageTimer && this.canChange) {
        clearTimeout(this.pageTimer);
        this.pageTimer = null;
      }
      if (this.canChange) {
        this.canChange = false;
        this.pageTimer = setTimeout(() => {
          let pre = this.currentPage;
          if (type === 'next') {
            pre++;
            this.currentPage = Math.min(pre, this.pageCount);
            this.setCurrentCanvas();
          } else {
            pre--;
            this.currentPage = Math.max(pre, 1);
            this.setCurrentCanvas();
          }
        }, 300);
      }
    },
    newPageViewPdf() {
      if (this.imgUrl) {
        window.open(this.imgUrl);
      } else {
        window.open(this.$route.query.url);
      }
    },
    rotateImg() {
      this.$refs.imgViewer.RotateRight();
    }
  },
  components: { pdf, viewer }
};
</script>

<style lang="less" scoped>
.gg_view {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 9;
  background: rgba(0, 0, 0, 0.9);
  font-family: Roboto;
  .back-btn {
    vertical-align: middle;
    font-size: 24px;
    margin-right: 20px;
    cursor: pointer;
  }
  .gg_view_actions {
    z-index: 11111;
  }
  .rotate-btn {
    border-right: 1px solid rgba(255, 255, 255, 0.5);
  }
  &_head {
    height: 56px;
    line-height: 56px;
    color: #fff;
    padding: 0 20px;
    font-size: 14px;
    .pdfIcon {
      width: 20px;
      margin-right: 12px;
      vertical-align: middle;
    }
  }
  &_pdf {
    position: absolute;
    top: 72px;
    left: 0;
    right: 0;
    bottom: 72px;
    .pdf {
      position: absolute;
      left: 50%;
      top: 50%;
      &.can_move {
        // cursor: url('./cursor/curHand-1bTVw.cur'), grab;
        &:active {
          // cursor: url('./cursor/curFist-31ZFp.cur'), grabbing;
        }
      }
    }
    .iconfont {
      position: absolute;
      color: #fff;
      top: 50%;
      margin-top: -20px;
      width: 40px;
      height: 40px;
      background: #000;
      border-radius: 50%;
      cursor: pointer;
      font-size: 24px;
      text-align: center;
      line-height: 40px;
      &.iconnavigate_before-px {
        left: 20px;
      }
      &.iconnavigate_next-px {
        right: 20px;
      }
      &:hover {
        background: #1a73e8;
      }
    }
  }
  &_actions {
    background: #000000;
    border-radius: 4px;
    position: absolute;
    bottom: 16px;
    left: 50%;
    margin-left: -80px;
    display: flex;
    color: #fff;
    text-align: center;
    line-height: 40px;
    > li {
      padding: 8px;
      font-size: 24px;
      line-height: 24px;
      cursor: pointer;
      &.page_number {
        flex: unset;
        border-right: 1px solid rgba(255, 255, 255, 0.5);
        font-size: 14px;
        cursor: default;
        word-spacing: 6px;
      }
      &.disabled {
        color: #7f7f7f;
        cursor: not-allowed;
      }
    }
  }
  .print-btn {
    position: absolute;
    z-index: 1;
    top: 16px;
    color: #fff;
    right: 20px;
    cursor: pointer;
    display: flex;
    align-items: center;
    i {
      font-size: 24px;
    }
  }
}
</style>

<style lang="less">
.file-tooltip {
  background-color: #6f6f6f;
  padding: 5px 8px;
}
.file-tooltip-top {
  transform: translateY(6px);
}
.file-tooltip-bottom {
  transform: translateY(-6px);
}
</style>
